html {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-logo {
  height: 50px;
  align-self: center;
  pointer-events: none;
}

.App-header {
  margin-top: 15vh;
  font-size: 6rem;
  font-family: 'Inter', sans-serif;
  color: white;
  margin-right: 10px;
  align-items: stretch;
}

.Counter-button {
  font-size: 2rem;
  font-family: 'Inter', sans-serif;
  color: white;
  border: 2px solid #FFF;
  background-color: #282c34;
  cursor: pointer;
  padding: 15px;
  margin: 20px;
}

.buttons {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.App-button {
  background-color: #282c34;
  color: #FFF;
  border: 2px solid #FFF;
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  margin: 15px;
  padding: 5px 5px;
  width: 140px;
  transition-duration: 0.2s;
  cursor: pointer;
}

.App-button:hover {
  background-color: #FF4D03;
  color: #FFF;
}